<template>
  <div class="card mx-auto mw-900px" v-if="notifications.length">
    <div class="card-body d-flex flex-column align-items-center">
      <a
        v-for="notification in notifications"
        :key="notification.id"
        :class="
          notification.seen
            ? 'btn-light border'
            : 'btn-light-primary border border-light-primary'
        "
        class="col-12 btn mb-2 position-relative align-items-center border"
        @click="openNotificationLink(notification)"
      >
        <span
          class="fs-5 h-100 d-flex align-items-center justify-content-start text-start"
        >
          {{ notification.text }}
        </span>
      </a>
      <span class="text-primary" @click="fetchMoreNotifications">
        charger plus
      </span>
    </div>
  </div>
  <div class="card p-5" v-else>Aucune notification</div>
</template>

<script>
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { computed, onMounted } from "vue";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { openLinkAgriBest } from "@/core/helpers/globalJaya";

export default {
  name: "Notifications",
  setup() {
    const store = useStore();
    const router = useRouter();
    onMounted(() => {
      setCurrentPageTitle("Notifications");
      const notificationsNotSeen = notifications.value.filter((el) => {
        return !el.seen;
      });
      if (
        notificationsNotSeen.length !== store.getters.getNotificationCountSent
      ) {
        setNotificationsSeen(
          notifications.value.filter((el) => {
            return !el.seen;
          })
        );
      }
    });
    const notifications = computed(() => {
      return store.getters.getNotifications;
    });
    const setNotificationsSeen = (notificationsToUpdate) => {
      return Promise.all(
        notificationsToUpdate.map(async (notification) => {
          await store.dispatch(Actions.UPDATE_NOTIFICATION, {
            id: notification.id,
            seen: true,
          });
        })
      ).then((response) => {
        store.commit(
          Mutations.SET_NOTIFICATION_COUNT,
          store.getters.getNotificationCount - response.length
        );
        store.commit(
          Mutations.SET_NOTIFICATION_COUNT_SENT,
          store.getters.getNotificationCountSent + response.length
        );
      });
    };
    const fetchMoreNotifications = () => {
      store.dispatch(Actions.FETCH_NOTIFICATIONS, {}).then((response) => {
        setNotificationsSeen(
          response.filter((el) => {
            return !el.seen;
          })
        );
      });
    };
    const openNotificationLink = (notification) => {
      if (notification.external_link) {
        openLinkAgriBest(notification.external_link);
      } else {
        router.push(notification.internal_link);
      }
    };
    return { notifications, fetchMoreNotifications, openNotificationLink };
  },
};
</script>

<style lang="ts"></style>
